import axios from "axios";

const state = () => ({
  checkResult: {},
  zohoRefreshToken: null,
  syncStatus: {},
});

const mutations = {
  setCheckResult(state, result) {
    if (!result) {
      state.checkResult = { cognito: null, discourse: null, zoho: null };
      return;
    }
    state.checkResult.cognito = result.cognito;
    state.checkResult.discourse = result.discourse;
    state.checkResult.zoho = result.zoho;
  },
  setRefreshToken(state, refreshToken) {
    state.zohoRefreshToken = refreshToken;
  },
  setSyncStatus(state, result) {
    state.syncStatus = result;
  },
};

const actions = {
  async checkAPIKeys(context) {
    try {
      const lambdaFunctionUrl = process.env.VUE_APP_MAILSYNC_LAMBDA;
      if (!lambdaFunctionUrl)
        throw "Please upload a user synchronization Lambda function to AWS and set its URL as the VUE_APP_MAILSYNC_LAMBDA value in the .env file, then restart then app.";
      const response = await axios.get(lambdaFunctionUrl + "?action=check");
      console.log("lambda response", response);
      const result = response.data;
      context.commit("setCheckResult", result?.checkResult);
    } catch (error) {
      console.error(error);
      if (error?.response?.data && error.response.data.error) throw error.response.data.error;
      throw error;
    }
  },
  async getZohoRefreshToken(context, { accessCode }) {
    try {
      const lambdaFunctionUrl = process.env.VUE_APP_MAILSYNC_LAMBDA;
      const response = await axios.get(
        lambdaFunctionUrl + "?action=zoho_refresh_token&access_code=" + accessCode
      );
      const refreshToken = response.data.refreshToken;
      context.commit("setRefreshToken", refreshToken);
    } catch (error) {
      console.error(error);
      if (error?.response?.data && error.response.data.error) throw error.response.data.error;
      throw error; // rethrow the error
    }
  },
  async startSynchronization(context, options) {
    try {
      const lambdaFunctionUrl = process.env.VUE_APP_MAILSYNC_LAMBDA;
      const endpoint = lambdaFunctionUrl + (options?.dryRun ? "?action=dryrun" : "?action=sync");
      const response = await axios.get(endpoint);
      context.commit("setSyncStatus", response.data);
    } catch (error) {
      console.error(error);
      if (error?.response?.data && error.response.data.error) throw error.response.data.error;
      throw error; // rethrow the error
    }
  },
};

const getters = {};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
