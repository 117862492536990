/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const searchMWTModelsAnalytics = /* GraphQL */ `
  query SearchMWTModelsAnalytics(
    $filter: SearchableMWTModelsAnalyticsFilterInput
    $sort: [SearchableMWTModelsAnalyticsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMWTModelsAnalyticsAggregationInput]
  ) {
    searchMWTModelsAnalytics(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        sortBase
        username
        thingID
        thingOperation
        isMobile
        referringPage
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchMWTUserProfiles = /* GraphQL */ `
  query SearchMWTUserProfiles(
    $filter: SearchableMWTUserProfilesFilterInput
    $sort: [SearchableMWTUserProfilesSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMWTUserProfilesAggregationInput]
  ) {
    searchMWTUserProfiles(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        nickname
        about
        personalPage
        personalPhoto
        creatorLogo
        subscriptionId
        subscriptionName
        subscriptionExpiration
        subscriptionCustomerId
        subscriptionStatus
        experimental
        administrator
        website
        tipHTML
        cognitoUsername
        editor
        billingAddress
        deleteFlag
        customerSupport
        createdAt
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const searchMWTModels = /* GraphQL */ `
  query SearchMWTModels(
    $filter: SearchableMWTModelsFilterInput
    $sort: [SearchableMWTModelsSortInput]
    $limit: Int
    $nextToken: String
    $from: Int
    $aggregates: [SearchableMWTModelsAggregationInput]
  ) {
    searchMWTModels(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
      aggregates: $aggregates
    ) {
      items {
        id
        creator
        author
        location
        accessControl
        externalID
        scriptKey
        imageKey
        stlKey
        zipKey
        modules
        fonts
        name
        description
        price
        currency
        allowAdaptations
        allowCommercialUse
        publicDomain
        tags
        sortBase
        createdAt
        hidden
        private
        locked
        featured
        verified
        searchSort
        commentCnt
        favoriteCnt
        reviewerComments
        reviewerName
        updatedAt
      }
      nextToken
      total
      aggregateItems {
        name
        result {
          ... on SearchableAggregateScalarResult {
            value
          }
          ... on SearchableAggregateBucketResult {
            buckets {
              key
              doc_count
            }
          }
        }
      }
    }
  }
`;
export const getMWTModelsAnalytics = /* GraphQL */ `
  query GetMWTModelsAnalytics($id: ID!) {
    getMWTModelsAnalytics(id: $id) {
      id
      sortBase
      username
      thingID
      thingOperation
      isMobile
      referringPage
      createdAt
      updatedAt
    }
  }
`;
export const listMWTModelsAnalytics = /* GraphQL */ `
  query ListMWTModelsAnalytics(
    $id: ID
    $filter: ModelMWTModelsAnalyticsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTModelsAnalytics(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sortBase
        username
        thingID
        thingOperation
        isMobile
        referringPage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTOpenSCADLogs = /* GraphQL */ `
  query GetMWTOpenSCADLogs($id: String!, $requestId: String!) {
    getMWTOpenSCADLogs(id: $id, requestId: $requestId) {
      id
      requestId
      createdAt
      sourceLocation
      destinationLocation
      renderLog
      openscadInfo
      openscadVersion
      status
      errorMessage
      updatedAt
    }
  }
`;
export const listMWTOpenSCADLogs = /* GraphQL */ `
  query ListMWTOpenSCADLogs(
    $id: String
    $requestId: ModelStringKeyConditionInput
    $filter: ModelMWTOpenSCADLogsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTOpenSCADLogs(
      id: $id
      requestId: $requestId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        requestId
        createdAt
        sourceLocation
        destinationLocation
        renderLog
        openscadInfo
        openscadVersion
        status
        errorMessage
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTControls = /* GraphQL */ `
  query GetMWTControls($id: ID!) {
    getMWTControls(id: $id) {
      id
      allFree
      debugMode
      rootPassword
      maintananceMode
      maintenanceMessage
      createdAt
      updatedAt
    }
  }
`;
export const listMWTControls = /* GraphQL */ `
  query ListMWTControls(
    $id: ID
    $filter: ModelMWTControlsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTControls(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        allFree
        debugMode
        rootPassword
        maintananceMode
        maintenanceMessage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTUserProfiles = /* GraphQL */ `
  query GetMWTUserProfiles($id: ID!) {
    getMWTUserProfiles(id: $id) {
      id
      nickname
      about
      personalPage
      personalPhoto
      creatorLogo
      subscriptionId
      subscriptionName
      subscriptionExpiration
      subscriptionCustomerId
      subscriptionStatus
      experimental
      models {
        nextToken
      }
      parameters {
        nextToken
      }
      administrator
      website
      tipHTML
      cognitoUsername
      editor
      billingAddress
      deleteFlag
      customerSupport
      createdAt
      updatedAt
    }
  }
`;
export const listMWTUserProfiles = /* GraphQL */ `
  query ListMWTUserProfiles(
    $id: ID
    $filter: ModelMWTUserProfilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTUserProfiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        nickname
        about
        personalPage
        personalPhoto
        creatorLogo
        subscriptionId
        subscriptionName
        subscriptionExpiration
        subscriptionCustomerId
        subscriptionStatus
        experimental
        administrator
        website
        tipHTML
        cognitoUsername
        editor
        billingAddress
        deleteFlag
        customerSupport
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTParameterSets = /* GraphQL */ `
  query GetMWTParameterSets($id: ID!) {
    getMWTParameterSets(id: $id) {
      id
      userID
      parameterSetName
      modelLocation
      modelID
      customParameters
      createdAt
      updatedAt
    }
  }
`;
export const listMWTParameterSets = /* GraphQL */ `
  query ListMWTParameterSets(
    $id: ID
    $filter: ModelMWTParameterSetsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTParameterSets(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        parameterSetName
        modelLocation
        modelID
        customParameters
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTModels = /* GraphQL */ `
  query GetMWTModels($id: ID!) {
    getMWTModels(id: $id) {
      id
      creator
      author
      location
      accessControl
      externalID
      scriptKey
      imageKey
      stlKey
      zipKey
      additionalFiles {
        nextToken
      }
      modules
      fonts
      name
      description
      price
      currency
      allowAdaptations
      allowCommercialUse
      publicDomain
      comments {
        nextToken
      }
      tags
      sortBase
      createdAt
      hidden
      private
      locked
      featured
      verified
      searchSort
      commentCnt
      favoriteCnt
      reviewerComments
      reviewerName
      updatedAt
    }
  }
`;
export const listMWTModels = /* GraphQL */ `
  query ListMWTModels(
    $id: ID
    $filter: ModelMWTModelsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTModels(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        creator
        author
        location
        accessControl
        externalID
        scriptKey
        imageKey
        stlKey
        zipKey
        modules
        fonts
        name
        description
        price
        currency
        allowAdaptations
        allowCommercialUse
        publicDomain
        tags
        sortBase
        createdAt
        hidden
        private
        locked
        featured
        verified
        searchSort
        commentCnt
        favoriteCnt
        reviewerComments
        reviewerName
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTAdditionalFiles = /* GraphQL */ `
  query GetMWTAdditionalFiles($id: ID!) {
    getMWTAdditionalFiles(id: $id) {
      id
      modelID
      fileType
      fileKey
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const listMWTAdditionalFiles = /* GraphQL */ `
  query ListMWTAdditionalFiles(
    $id: ID
    $filter: ModelMWTAdditionalFilesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTAdditionalFiles(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        modelID
        fileType
        fileKey
        thumbnail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTComments = /* GraphQL */ `
  query GetMWTComments($id: ID!) {
    getMWTComments(id: $id) {
      id
      modelCreator
      modelID
      commentText
      commentUser
      sortBase
      createdAt
      updatedAt
    }
  }
`;
export const listMWTComments = /* GraphQL */ `
  query ListMWTComments(
    $id: ID
    $filter: ModelMWTCommentsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTComments(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        modelCreator
        modelID
        commentText
        commentUser
        sortBase
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMWTFavorites = /* GraphQL */ `
  query GetMWTFavorites($id: ID!) {
    getMWTFavorites(id: $id) {
      id
      userID
      modelID
      userNotes
      createdAt
      updatedAt
    }
  }
`;
export const listMWTFavorites = /* GraphQL */ `
  query ListMWTFavorites(
    $id: ID
    $filter: ModelMWTFavoritesFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMWTFavorites(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        userID
        modelID
        userNotes
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAnalyticsByDate = /* GraphQL */ `
  query ListAnalyticsByDate(
    $sortBase: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMWTModelsAnalyticsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAnalyticsByDate(
      sortBase: $sortBase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sortBase
        username
        thingID
        thingOperation
        isMobile
        referringPage
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listModelsByDate = /* GraphQL */ `
  query ListModelsByDate(
    $sortBase: String!
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMWTModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModelsByDate(
      sortBase: $sortBase
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        creator
        author
        location
        accessControl
        externalID
        scriptKey
        imageKey
        stlKey
        zipKey
        modules
        fonts
        name
        description
        price
        currency
        allowAdaptations
        allowCommercialUse
        publicDomain
        tags
        sortBase
        createdAt
        hidden
        private
        locked
        featured
        verified
        searchSort
        commentCnt
        favoriteCnt
        reviewerComments
        reviewerName
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAdditionalFilesByModelID = /* GraphQL */ `
  query ListAdditionalFilesByModelID(
    $modelID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMWTAdditionalFilesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAdditionalFilesByModelID(
      modelID: $modelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelID
        fileType
        fileKey
        thumbnail
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCommentsByModel = /* GraphQL */ `
  query ListCommentsByModel(
    $sortBase: String!
    $modelID: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelMWTCommentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCommentsByModel(
      sortBase: $sortBase
      modelID: $modelID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        modelCreator
        modelID
        commentText
        commentUser
        sortBase
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
