/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createMWTModelsAnalytics = /* GraphQL */ `
  mutation CreateMWTModelsAnalytics(
    $input: CreateMWTModelsAnalyticsInput!
    $condition: ModelMWTModelsAnalyticsConditionInput
  ) {
    createMWTModelsAnalytics(input: $input, condition: $condition) {
      id
      sortBase
      username
      thingID
      thingOperation
      isMobile
      referringPage
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTModelsAnalytics = /* GraphQL */ `
  mutation UpdateMWTModelsAnalytics(
    $input: UpdateMWTModelsAnalyticsInput!
    $condition: ModelMWTModelsAnalyticsConditionInput
  ) {
    updateMWTModelsAnalytics(input: $input, condition: $condition) {
      id
      sortBase
      username
      thingID
      thingOperation
      isMobile
      referringPage
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTModelsAnalytics = /* GraphQL */ `
  mutation DeleteMWTModelsAnalytics(
    $input: DeleteMWTModelsAnalyticsInput!
    $condition: ModelMWTModelsAnalyticsConditionInput
  ) {
    deleteMWTModelsAnalytics(input: $input, condition: $condition) {
      id
      sortBase
      username
      thingID
      thingOperation
      isMobile
      referringPage
      createdAt
      updatedAt
    }
  }
`;
export const createMWTOpenSCADLogs = /* GraphQL */ `
  mutation CreateMWTOpenSCADLogs(
    $input: CreateMWTOpenSCADLogsInput!
    $condition: ModelMWTOpenSCADLogsConditionInput
  ) {
    createMWTOpenSCADLogs(input: $input, condition: $condition) {
      id
      requestId
      createdAt
      sourceLocation
      destinationLocation
      renderLog
      openscadInfo
      openscadVersion
      status
      errorMessage
      updatedAt
    }
  }
`;
export const updateMWTOpenSCADLogs = /* GraphQL */ `
  mutation UpdateMWTOpenSCADLogs(
    $input: UpdateMWTOpenSCADLogsInput!
    $condition: ModelMWTOpenSCADLogsConditionInput
  ) {
    updateMWTOpenSCADLogs(input: $input, condition: $condition) {
      id
      requestId
      createdAt
      sourceLocation
      destinationLocation
      renderLog
      openscadInfo
      openscadVersion
      status
      errorMessage
      updatedAt
    }
  }
`;
export const deleteMWTOpenSCADLogs = /* GraphQL */ `
  mutation DeleteMWTOpenSCADLogs(
    $input: DeleteMWTOpenSCADLogsInput!
    $condition: ModelMWTOpenSCADLogsConditionInput
  ) {
    deleteMWTOpenSCADLogs(input: $input, condition: $condition) {
      id
      requestId
      createdAt
      sourceLocation
      destinationLocation
      renderLog
      openscadInfo
      openscadVersion
      status
      errorMessage
      updatedAt
    }
  }
`;
export const createMWTControls = /* GraphQL */ `
  mutation CreateMWTControls(
    $input: CreateMWTControlsInput!
    $condition: ModelMWTControlsConditionInput
  ) {
    createMWTControls(input: $input, condition: $condition) {
      id
      allFree
      debugMode
      rootPassword
      maintananceMode
      maintenanceMessage
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTControls = /* GraphQL */ `
  mutation UpdateMWTControls(
    $input: UpdateMWTControlsInput!
    $condition: ModelMWTControlsConditionInput
  ) {
    updateMWTControls(input: $input, condition: $condition) {
      id
      allFree
      debugMode
      rootPassword
      maintananceMode
      maintenanceMessage
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTControls = /* GraphQL */ `
  mutation DeleteMWTControls(
    $input: DeleteMWTControlsInput!
    $condition: ModelMWTControlsConditionInput
  ) {
    deleteMWTControls(input: $input, condition: $condition) {
      id
      allFree
      debugMode
      rootPassword
      maintananceMode
      maintenanceMessage
      createdAt
      updatedAt
    }
  }
`;
export const createMWTUserProfiles = /* GraphQL */ `
  mutation CreateMWTUserProfiles(
    $input: CreateMWTUserProfilesInput!
    $condition: ModelMWTUserProfilesConditionInput
  ) {
    createMWTUserProfiles(input: $input, condition: $condition) {
      id
      nickname
      about
      personalPage
      personalPhoto
      creatorLogo
      subscriptionId
      subscriptionName
      subscriptionExpiration
      subscriptionCustomerId
      subscriptionStatus
      experimental
      models {
        nextToken
      }
      parameters {
        nextToken
      }
      administrator
      website
      tipHTML
      cognitoUsername
      editor
      billingAddress
      deleteFlag
      customerSupport
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTUserProfiles = /* GraphQL */ `
  mutation UpdateMWTUserProfiles(
    $input: UpdateMWTUserProfilesInput!
    $condition: ModelMWTUserProfilesConditionInput
  ) {
    updateMWTUserProfiles(input: $input, condition: $condition) {
      id
      nickname
      about
      personalPage
      personalPhoto
      creatorLogo
      subscriptionId
      subscriptionName
      subscriptionExpiration
      subscriptionCustomerId
      subscriptionStatus
      experimental
      models {
        nextToken
      }
      parameters {
        nextToken
      }
      administrator
      website
      tipHTML
      cognitoUsername
      editor
      billingAddress
      deleteFlag
      customerSupport
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTUserProfiles = /* GraphQL */ `
  mutation DeleteMWTUserProfiles(
    $input: DeleteMWTUserProfilesInput!
    $condition: ModelMWTUserProfilesConditionInput
  ) {
    deleteMWTUserProfiles(input: $input, condition: $condition) {
      id
      nickname
      about
      personalPage
      personalPhoto
      creatorLogo
      subscriptionId
      subscriptionName
      subscriptionExpiration
      subscriptionCustomerId
      subscriptionStatus
      experimental
      models {
        nextToken
      }
      parameters {
        nextToken
      }
      administrator
      website
      tipHTML
      cognitoUsername
      editor
      billingAddress
      deleteFlag
      customerSupport
      createdAt
      updatedAt
    }
  }
`;
export const createMWTParameterSets = /* GraphQL */ `
  mutation CreateMWTParameterSets(
    $input: CreateMWTParameterSetsInput!
    $condition: ModelMWTParameterSetsConditionInput
  ) {
    createMWTParameterSets(input: $input, condition: $condition) {
      id
      userID
      parameterSetName
      modelLocation
      modelID
      customParameters
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTParameterSets = /* GraphQL */ `
  mutation UpdateMWTParameterSets(
    $input: UpdateMWTParameterSetsInput!
    $condition: ModelMWTParameterSetsConditionInput
  ) {
    updateMWTParameterSets(input: $input, condition: $condition) {
      id
      userID
      parameterSetName
      modelLocation
      modelID
      customParameters
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTParameterSets = /* GraphQL */ `
  mutation DeleteMWTParameterSets(
    $input: DeleteMWTParameterSetsInput!
    $condition: ModelMWTParameterSetsConditionInput
  ) {
    deleteMWTParameterSets(input: $input, condition: $condition) {
      id
      userID
      parameterSetName
      modelLocation
      modelID
      customParameters
      createdAt
      updatedAt
    }
  }
`;
export const createMWTModels = /* GraphQL */ `
  mutation CreateMWTModels(
    $input: CreateMWTModelsInput!
    $condition: ModelMWTModelsConditionInput
  ) {
    createMWTModels(input: $input, condition: $condition) {
      id
      creator
      author
      location
      accessControl
      externalID
      scriptKey
      imageKey
      stlKey
      zipKey
      additionalFiles {
        nextToken
      }
      modules
      fonts
      name
      description
      price
      currency
      allowAdaptations
      allowCommercialUse
      publicDomain
      comments {
        nextToken
      }
      tags
      sortBase
      createdAt
      hidden
      private
      locked
      featured
      verified
      searchSort
      commentCnt
      favoriteCnt
      reviewerComments
      reviewerName
      updatedAt
    }
  }
`;
export const updateMWTModels = /* GraphQL */ `
  mutation UpdateMWTModels(
    $input: UpdateMWTModelsInput!
    $condition: ModelMWTModelsConditionInput
  ) {
    updateMWTModels(input: $input, condition: $condition) {
      id
      creator
      author
      location
      accessControl
      externalID
      scriptKey
      imageKey
      stlKey
      zipKey
      additionalFiles {
        nextToken
      }
      modules
      fonts
      name
      description
      price
      currency
      allowAdaptations
      allowCommercialUse
      publicDomain
      comments {
        nextToken
      }
      tags
      sortBase
      createdAt
      hidden
      private
      locked
      featured
      verified
      searchSort
      commentCnt
      favoriteCnt
      reviewerComments
      reviewerName
      updatedAt
    }
  }
`;
export const deleteMWTModels = /* GraphQL */ `
  mutation DeleteMWTModels(
    $input: DeleteMWTModelsInput!
    $condition: ModelMWTModelsConditionInput
  ) {
    deleteMWTModels(input: $input, condition: $condition) {
      id
      creator
      author
      location
      accessControl
      externalID
      scriptKey
      imageKey
      stlKey
      zipKey
      additionalFiles {
        nextToken
      }
      modules
      fonts
      name
      description
      price
      currency
      allowAdaptations
      allowCommercialUse
      publicDomain
      comments {
        nextToken
      }
      tags
      sortBase
      createdAt
      hidden
      private
      locked
      featured
      verified
      searchSort
      commentCnt
      favoriteCnt
      reviewerComments
      reviewerName
      updatedAt
    }
  }
`;
export const createMWTAdditionalFiles = /* GraphQL */ `
  mutation CreateMWTAdditionalFiles(
    $input: CreateMWTAdditionalFilesInput!
    $condition: ModelMWTAdditionalFilesConditionInput
  ) {
    createMWTAdditionalFiles(input: $input, condition: $condition) {
      id
      modelID
      fileType
      fileKey
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTAdditionalFiles = /* GraphQL */ `
  mutation UpdateMWTAdditionalFiles(
    $input: UpdateMWTAdditionalFilesInput!
    $condition: ModelMWTAdditionalFilesConditionInput
  ) {
    updateMWTAdditionalFiles(input: $input, condition: $condition) {
      id
      modelID
      fileType
      fileKey
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTAdditionalFiles = /* GraphQL */ `
  mutation DeleteMWTAdditionalFiles(
    $input: DeleteMWTAdditionalFilesInput!
    $condition: ModelMWTAdditionalFilesConditionInput
  ) {
    deleteMWTAdditionalFiles(input: $input, condition: $condition) {
      id
      modelID
      fileType
      fileKey
      thumbnail
      createdAt
      updatedAt
    }
  }
`;
export const createMWTComments = /* GraphQL */ `
  mutation CreateMWTComments(
    $input: CreateMWTCommentsInput!
    $condition: ModelMWTCommentsConditionInput
  ) {
    createMWTComments(input: $input, condition: $condition) {
      id
      modelCreator
      modelID
      commentText
      commentUser
      sortBase
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTComments = /* GraphQL */ `
  mutation UpdateMWTComments(
    $input: UpdateMWTCommentsInput!
    $condition: ModelMWTCommentsConditionInput
  ) {
    updateMWTComments(input: $input, condition: $condition) {
      id
      modelCreator
      modelID
      commentText
      commentUser
      sortBase
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTComments = /* GraphQL */ `
  mutation DeleteMWTComments(
    $input: DeleteMWTCommentsInput!
    $condition: ModelMWTCommentsConditionInput
  ) {
    deleteMWTComments(input: $input, condition: $condition) {
      id
      modelCreator
      modelID
      commentText
      commentUser
      sortBase
      createdAt
      updatedAt
    }
  }
`;
export const createMWTFavorites = /* GraphQL */ `
  mutation CreateMWTFavorites(
    $input: CreateMWTFavoritesInput!
    $condition: ModelMWTFavoritesConditionInput
  ) {
    createMWTFavorites(input: $input, condition: $condition) {
      id
      userID
      modelID
      userNotes
      createdAt
      updatedAt
    }
  }
`;
export const updateMWTFavorites = /* GraphQL */ `
  mutation UpdateMWTFavorites(
    $input: UpdateMWTFavoritesInput!
    $condition: ModelMWTFavoritesConditionInput
  ) {
    updateMWTFavorites(input: $input, condition: $condition) {
      id
      userID
      modelID
      userNotes
      createdAt
      updatedAt
    }
  }
`;
export const deleteMWTFavorites = /* GraphQL */ `
  mutation DeleteMWTFavorites(
    $input: DeleteMWTFavoritesInput!
    $condition: ModelMWTFavoritesConditionInput
  ) {
    deleteMWTFavorites(input: $input, condition: $condition) {
      id
      userID
      modelID
      userNotes
      createdAt
      updatedAt
    }
  }
`;
