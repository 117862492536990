import Vue from "vue";
import App from "./App.vue";
import { Auth } from "aws-amplify";
import { API } from "aws-amplify";
import * as queries from "./graphql/queries";
import vuetify from "./plugins/vuetify";
import { store } from "./store";
import router from "./router";

import "material-design-icons-iconfont/dist/material-design-icons.css";

// AWS Amplify configuration for Vue 2.X
// This will need to change if/when we upgrade to Vue 3.X
import aws_exports from "./aws-exports";
import { applyPolyfills, defineCustomElements } from "@aws-amplify/ui-components/loader";
import { Amplify } from "aws-amplify";
Amplify.configure(aws_exports);
applyPolyfills().then(() => {
  defineCustomElements(window);
});
Vue.config.ignoredElements = [/amplify-\w*/];

Vue.mixin({
  methods: {
    checkLoginStatus: async function () {
      //
      // This function saves all data to Vuex variables
      // This allows us to check for authorization in one central place
      //
      this.$store.state.loginMessageTrigger = false;

      let cognitoUser;
      try {
        cognitoUser = await Auth.currentAuthenticatedUser({
          bypassCache: false,
        });
      } catch (e) {
        // ignore: user not logged in ..
      }

      if (cognitoUser) {
        this.$store.state.cognitoUser = cognitoUser;
      } else {
        this.$store.state.cognitoUser = null;
      }
    },
    checkAuthorization: function () {
      if (this.$store.state.cognitoUser === null) {
        this.$store.state.loginMessage = "No Authorization";
        this.$store.state.loginMessageTrigger = true;
        return false;
      }
      return true;
    },
    setAdminLevel: async function () {
      let userProfile;
      if (this.$store.state.cognitoUser !== null) {
        try {
          userProfile = await API.graphql({
            query: queries.getMWTUserProfiles,
            variables: { id: this.$store.state.cognitoUser.attributes.sub },
          });
          if (userProfile.data.getMWTUserProfiles !== null) {
            this.$store.state.administrator =
              userProfile.data.getMWTUserProfiles.administrator || false;
            this.$store.state.editor = userProfile.data.getMWTUserProfiles.editor || false;
          } else {
            alert("No user profile found when checking admin level");
          }
        } catch (error) {
          console.log("not logged in", error);
        }
      }
    },
  },
});

new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
