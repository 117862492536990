<template>
  <div class="account-page pb-16">
    <v-container fluid>
      <!-- Main Content Area Starts Here -->

      <v-row justify="space-around">
        <v-col
          col="12"
          md="5">
          <p class="mt-6 text-h3 text-center">Administration Utilities</p>

          <div class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SIGN FOR THE EXCLUSTIVE OF USE MAKEWITHTECH AUTHORIZED PERSONEL
          </div>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col
          col="12"
          md="5"
          v-if="$store.state.cognitoUser === null">
          <div class="text-subtitle-1 font-weight-bold text-center text-sm-left pb-2">
            Username *
          </div>
          <v-text-field
            v-model="username"
            label="Enter your username..."
            outlined
            data-cy="loginUsername"
            id="username">
          </v-text-field>
          <div class="text-subtitle-1 font-weight-bold text-center text-sm-left pb-2">
            Password *
          </div>
          <v-text-field
            v-model="password"
            label="Enter your password..."
            outlined
            data-cy="loginPassword"
            id="password"
            :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPassword ? 'text' : 'password'"
            @click:append="showPassword = !showPassword">
          </v-text-field>
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="signIn()"
            block
            x-large
            class="text-subtitle-1 secondary white--text"
            data-cy="signIn">
            SIGN IN
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="5"
          v-else>
          <h1 class="text-center">
            You are logged in as {{ this.$store.state.cognitoUser?.username }}
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style></style>

<script>
  import { Auth } from "aws-amplify";

  export default {
    name: "LoginPage",
    components: {},
    data() {
      return {
        loading: false,
        username: "",
        password: "",
        showPassword: false,
      };
    },
    async mounted() {
      this.checkLoginStatus();
    },
    methods: {
      async signIn() {
        this.loading = true;
        let editor;
        let administrator;
        this.$store.state.loginMessage = "";
        this.$store.state.loginMessageTrigger = false;
        try {
          await Auth.signIn(this.username, this.password);
          this.$store.state.cognitoUser = await Auth.currentUserInfo();
          if (this.$store.state.cognitoUser?.username) {
            await this.setAdminLevel();
            administrator = this.$store.state.administrator;
            editor = this.$store.state.editor;
          }
          if (!administrator && !editor) {
            await Auth.signOut({
              global: true,
            });
            this.loading = false;
            this.$store.state.loginMessage = "";
            this.$store.state.loginMessageTrigger = false;
            this.$store.state.cognitoUser = null;
            alert(
              "You must have editor or administrator privilege to use this utility.  Returning to the home page."
            );
            this.$router.push({
              path: "/",
            });
          } else {
            this.$store.state.loginMessage = `Login Successful <br />Administrator: ${administrator} <br />Editor: ${editor}`;
            this.$store.state.loginMessageTrigger = true;
            this.loading = false;
          }
        } catch (error) {
          console.error("login failure: ", error);
          this.loading = false;
          this.$store.state.loginMessage = "****** Login Failure ******";
          this.$store.state.loginMessageTrigger = true;
        }
      },
    },
  };
</script>
