<template>
  <v-container fluid>
    <v-sheet
      height="600"
      class="background-image">
      <v-row justify="space-around">
        <v-col>
          <p class="mt-5 text-h3 text-center">User Management</p>

          <p class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SITE FOR THE EXCLUSIVE OF USE MAKEWITHTECH AUTHORIZED PERSONNEL
          </p>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col
          col="12"
          md="7">
          <div class="text-subtitle-1 font-weight-bold text-center text-sm-left pb-2">
            Partial or full email address
          </div>
          <v-text-field
            v-model="emailAddress"
            label="Enter the uses email address ..."
            outlined
            data-cy="emailAddress"
            id="emailAddress">
          </v-text-field>
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="getUserByEmail()"
            block
            x-large
            class="text-subtitle-1 secondary white--text"
            data-cy="lookUpUser">
            Lookup User
          </v-btn>
        </v-col>
      </v-row>
      <div v-if="noUsersFound">
        <v-row justify="space-around">
          <v-col
            col="12"
            md="8">
            <h2 class="text-center red--text">No User(s) Found</h2>
          </v-col>
        </v-row>
      </div>
      <div v-if="deleteAborted">
        <v-row justify="space-around">
          <v-col
            col="12"
            md="8">
            <h2 class="text-center red--text">Delete of User Aborted</h2>
          </v-col>
        </v-row>
      </div>
      <v-row justify="space-around">
        <v-col
          col="12"
          md="8">
          <v-data-table
            :headers="headers"
            :items="users"
            :items-per-page="5"
            class="elevation-1"
            :loading="myLoadingVariable">
            <template v-slot:item="{ item }">
              <tr class="text-caption">
                <td class="text-caption">{{ item.userName }}</td>
                <td class="text-caption">{{ item.userEmail }}</td>
                <td class="text-caption">{{ item.cognitoSub }}</td>
                <td class="text-caption">{{ item.userStatus }}</td>
                <td
                  align="right"
                  class="text-caption">
                  <v-btn
                    v-if="item.userStatus !== 'CONFIRMED'"
                    :loading="verifyLoading"
                    x-small
                    plain
                    @click="verify_user(item)">
                    <v-icon> mdi-check </v-icon>
                  </v-btn>
                  <v-btn
                    x-small
                    plain
                    @click="display_subscription(item)">
                    <v-icon> mdi-key </v-icon>
                  </v-btn>
                  <v-btn
                    class="ml-3"
                    x-small
                    plain
                    @click="delete_user(item)">
                    <v-icon> mdi-trash-can-outline </v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-sheet>
    <v-dialog
      v-model="showCustomer"
      max-width="600">
      <v-card>
        <v-card-text class="text-center">
          <vue-json-pretty
            virtual
            :data="customerData" />
        </v-card-text>
        <v-card-actions>
          <v-btn @click="showCustomer = false"> Ok </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="subscriptionDialog"
      max-width="700">
      <v-card>
        <v-card-title class="text-h5"> Subscription Information - Edit Carefully </v-card-title>
        <v-card-title>Registered User: {{ subscriptionUserName }}</v-card-title>
        <v-card-text v-if="!subscriptionId">
          <h3>No Subscription Found for User</h3>
        </v-card-text>
        <v-card-text class="text-xs-center">
          <p
            class="font-weight-bold text-center"
            style="color: green">
            For a courtesy subscription enter: <br />
            &nbsp;&nbsp;&nbsp;id=999999, name="Premium Courtesy", <br />
            &nbsp;&nbsp;&nbsp;status="active", expiration="YYYY-MM-DDT00:00:00.000Z"
          </p>
          <v-btn
            class="ma-3 text-center"
            small
            @click="premium_courtesy()">
            Set as Premium Courtesy
          </v-btn>
          <v-text-field
            v-model="subscriptionCustomerId"
            label="subscription customer id">
          </v-text-field>
          <v-text-field
            v-model="subscriptionId"
            label="subscription id">
          </v-text-field>
          <v-text-field
            v-model="subscriptionName"
            label="subscription name">
          </v-text-field>
          <v-text-field
            v-model="subscriptionExpiration"
            label="subscription expiration">
          </v-text-field>
          <v-text-field
            v-model="subscriptionStatus"
            label="subscription status">
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="green darken-1"
            text
            @click="subscriptionDialog = false">
            cancel
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            :loading="updateLoading"
            @click="update_subscription()">
            update
          </v-btn>
          <v-btn
            v-if="subscriptionCustomerId"
            color="green darken-1"
            text
            @click="display_customer_from_stripe()">
            Stripe Customer Info
          </v-btn>
          <v-spacer />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<style>
  .background-image {
    background: url("../../public/bg.jpg") no-repeat top 10px center / cover;
    background-size: cover;
  }
</style>

<script>
  import {
    CognitoIdentityProviderClient,
    ListUsersCommand,
    AdminConfirmSignUpCommand,
    AdminDeleteUserCommand,
  } from "@aws-sdk/client-cognito-identity-provider";
  import config from "../config.json";
  // IMS import { Auth, API } from "aws-amplify";
  import { API, Auth } from "aws-amplify";
  import * as queries from "../graphql/queries";
  import * as mutations from "../graphql/mutations";
  import VueJsonPretty from "vue-json-pretty";
  import "vue-json-pretty/lib/styles.css";

  export default {
    name: "LoginPage",
    components: {
      VueJsonPretty,
    },
    data() {
      return {
        myLoadingVariable: false,
        updateLoading: false,
        // receiptLoading: false,
        emailAddress: "",
        subscriptionDialog: false,
        loading: false,
        users: [],
        userProfile: {},
        currentUserId: "",
        subscriptionId: "",
        subscriptionName: "",
        subscriptionExpiration: "",
        subscriptionCustomerId: "",
        subscriptionStatus: "",
        subscriptionUserName: "",
        noUsersFound: false,
        deleteAborted: false,
        customerData: {},
        showCustomer: false,
        stripeApiUrl: "",
        headers: [
          {
            text: "User Name",
            value: "userName",
            width: "17%",
          },
          {
            text: "Email",
            value: "userEmail",
            width: "20%",
          },
          {
            text: "CognitoSub",
            value: "CognitoSub",
            width: "33%",
          },
          {
            text: "Status",
            value: "userStatus",
            width: "15%",
          },
          {
            text: "Actions",
            value: "action",
            align: "center",
            width: "15%",
            sortable: false,
          },
        ],
      };
    },
    mounted() {
      const branch = process.env.VUE_APP_BRANCH;
      if (branch === "dev") {
        this.stripeApiUrl =
          "https://i1zyjxoc73.execute-api.us-east-1.amazonaws.com/dev/v1/customers/";
      } else {
        this.stripeApiUrl =
          "https://z1ja74lif1.execute-api.us-east-1.amazonaws.com/prod/v1/customers/";
      }
    },
    methods: {
      validate_iso_date(myDate) {
        if (!/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/.test(myDate)) return false;
        return true;
      },
      getUserByEmail: async function () {
        this.deleteAborted = false;
        this.users = [];
        this.myLoadingVariable = true;
        this.noUsersFound = false;
        const credentials = Auth.currentCredentials();
        const cognitoClient = new CognitoIdentityProviderClient({
          region: config.region,
          credentials: credentials,
        });

        const params = {
          UserPoolId: config.userPoolId,
          Filter: `email ^= "${this.emailAddress}"`,
        };

        const cognitoCommand = new ListUsersCommand(params);

        try {
          const data = await cognitoClient.send(cognitoCommand);
          if (data.Users.length > 0) {
            this.noUsersFound = false;
          } else {
            this.noUsersFound = true;
            this.users = [];
          }
          let cognitoSubId;
          let userEmail;
          //
          // The structure of the data is and array of Users
          // Each user has some based values and then an array of attributes
          //
          for (let i = 0; i < data.Users.length; i++) {
            cognitoSubId = "";
            userEmail = "";
            for (let s = 0; s < data.Users[i].Attributes.length; s++) {
              if (data.Users[i].Attributes[s].Name == "sub") {
                cognitoSubId = data.Users[i].Attributes[s].Value;
              }
              if (data.Users[i].Attributes[s].Name == "email") {
                userEmail = data.Users[i].Attributes[s].Value;
              }
            }
            this.users.push({
              userName: data.Users[i].Username,
              userEmail: userEmail,
              userStatus: data.Users[i].UserStatus,
              cognitoSub: cognitoSubId,
            });
          }
        } catch (error) {
          console.error(error);
        }
        this.myLoadingVariable = false;
      },
      verify_user: async function (item) {
        this.verifyLoading = true;
        this.users = [];
        this.deleteAborted = false;
        this.noUsersFound = false;
        const credentials = Auth.currentCredentials();
        const cognitoClient = new CognitoIdentityProviderClient({
          region: config.region,
          credentials: credentials,
        });

        const params = {
          UserPoolId: config.userPoolId,
          Username: item.userName,
        };

        const cognitoCommand = new AdminConfirmSignUpCommand(params);

        try {
          await cognitoClient.send(cognitoCommand);
          alert(`User confirmed successfully`);
        } catch (error) {
          alert(`User confirmation error ${error}`);
        }
        this.verifyLoading = false;
      },
      display_subscription: async function (item) {
        //
        // Make sure we start with clear global fields used in pop up
        //
        this.subscriptionId = "";
        this.subscriptionName = "";
        this.subscriptionExpiration = "";
        this.subscriptionCustomerId = "";
        this.subscriptionStatus = "";
        this.subscriptionUserName = "";

        this.subscriptionDialog = true;
        this.currentUserId = item.cognitoSub;
        console.log("update subscription item: ", item);
        try {
          this.userProfile = await API.graphql({
            query: queries.getMWTUserProfiles,
            variables: { id: item.cognitoSub },
          });
          console.log("display_subscription (userProfile): ", this.userProfile);
          if (this.userProfile.data.getMWTUserProfiles !== null) {
            this.subscriptionId = this.userProfile.data.getMWTUserProfiles.subscriptionId || "";
            this.subscriptionName = this.userProfile.data.getMWTUserProfiles.subscriptionName || "";
            this.subscriptionExpiration =
              this.userProfile.data.getMWTUserProfiles.subscriptionExpiration || "";
            this.subscriptionCustomerId =
              this.userProfile.data.getMWTUserProfiles.subscriptionCustomerId || "";
            this.subscriptionStatus =
              this.userProfile.data.getMWTUserProfiles.subscriptionStatus || "";
            this.subscriptionUserName = item.userName;
          }
        } catch (error) {
          console.log("not logged in", error);
          this.subscriptionId = "";
          this.subscriptionName = "";
          this.subscriptionExpiration = "";
          this.subscriptionCustomerId = "";
          this.subscriptionStatus = "";
          this.subscriptionUserName = "";
        }
      },
      premium_courtesy: function () {
        if (this.subscriptionId) {
          alert("To overwrite and existing subscription manually update the fields.");
          return;
        }
        this.subscriptionId = "999999";
        this.subscriptionName = "Premium Courtesy";
        this.subscriptionExpiration = "2099-01-01T00:00:00.000Z";
        this.subscriptionCustomerId = "999999";
        this.subscriptionStatus = "active";
        return;
      },
      display_customer_from_stripe: async function () {
        let idToken;
        idToken = await Auth.currentSession().then((session) => session.getIdToken());
        let apiUrl = this.stripeApiUrl + this.subscriptionCustomerId;
        try {
          // Send the request to the OpenAI API
          const response = await fetch(apiUrl, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: idToken.jwtToken,
            },
          });
          // Parse the response and return the generated text
          this.customerData = await response.json();
          console.log("Customer Data: ", this.customerData);
          this.subscriptionDialog = false;
          this.showCustomer = true;
        } catch (error) {
          console.log("Unable to get stripe customer data from lambda");
        }
      },
      update_subscription: async function () {
        if (!this.validate_iso_date(this.subscriptionExpiration)) {
          alert("Invalid Subscription Expiration Data Format.  Correct.");
          return;
        }
        let updateConfirm = prompt("Enter UPDATE to confirm you want to update this subscriptions");
        if (updateConfirm === "UPDATE") {
          this.updateLoading = true;
          let accountRecord = {
            id: this.currentUserId,
            subscriptionId: this.subscriptionId,
            subscriptionName: this.subscriptionName,
            subscriptionExpiration: this.subscriptionExpiration,
            subscriptionCustomerId: this.subscriptionCustomerId,
            subscriptionStatus: this.subscriptionStatus,
          };
          console.log("update subscription: ", accountRecord);
          try {
            await API.graphql({
              query: mutations.updateMWTUserProfiles,
              variables: { input: accountRecord },
            });
            console.log("subscription fields in the profile record updated");
            alert("updated " + this.subscriptionUserName);
          } catch (error) {
            console.log("subscription data in account update failed: ", error);
            alert("updated failed " + this.subscriptionUserName + " " + JSON.stringify(error));
          }
        } else {
          alert("*** update skipped ***");
        }
        this.updateLoading = false;
        this.subscriptionDialog = false;
      },
      delete_user: async function (item) {
        this.users = [];
        this.deleteAborted = false;
        let promptReply = prompt(`To delete ${item.userName} please enter DELETE`);
        if (promptReply !== "DELETE") {
          this.deleteAborted = true;
          return;
        }
        this.noUsersFound = false;
        const credentials = Auth.currentCredentials();
        const cognitoClient = new CognitoIdentityProviderClient({
          region: config.region,
          credentials: credentials,
        });

        const params = {
          UserPoolId: config.userPoolId,
          Username: item.userName,
        };

        const cognitoCommand = new AdminDeleteUserCommand(params);

        try {
          await cognitoClient.send(cognitoCommand);
          alert(`User delete of Cognito record successfully`);
        } catch (error) {
          alert(`User delete of Cognito record failed,  error ${error}`);
        }

        let accountRecord = {
          id: item.cognitoSub,
        };

        try {
          await API.graphql({
            query: mutations.deleteMWTUserProfiles,
            variables: { input: accountRecord },
          });
          alert(`User delete of Database record successfully`);
        } catch (error) {
          alert(`User delete of Database record failed,  error ${error}`);
        }
      },
    },
  };
</script>
