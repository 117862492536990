/* spell-checker: disable */
import Vue from "vue";
import VueRouter from "vue-router";
import NotificationAdmin from "../components/NotificationAdmin.vue";
import HomePage from "../views/HomePage.vue";
import LoginPage from "../views/LoginPage.vue";
import LogoutPage from "../views/LogoutPage.vue";
import ApplicationMetrics from "../components/ApplicationMetrics.vue";
import ValidateUser from "../components/ValidateUser.vue";
import Maintenance from "../components/Maintenance.vue";
import MailSync from "../components/MailSync.vue";

import { store } from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/notifications",
    name: "NotificationsAdmin",
    component: NotificationAdmin,
  },
  {
    path: "/validate",
    name: "ValidateUser",
    component: ValidateUser,
  },
  {
    path: "/metrics",
    name: "ApplicationMetrics",
    component: ApplicationMetrics,
  },
  {
    path: "/mailsync",
    name: "MailSync",
    component: MailSync,
  },
  {
    path: "/maintenance",
    name: "Maintenance",
    component: Maintenance,
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/logout",
    name: "LogoutPage",
    component: LogoutPage,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.afterEach((to, from) => {
  //
  // use store vs $store since it is imported locally
  //
  store.commit("previous", from.fullPath);
});

export default router;
