<template>
  <v-app id="ModelAdmin">
    <!-- Menu Bar -->

    <v-app-bar
      height="80px"
      elevation="0"
      app
      key="$store.state.cognitoUser">
      <v-toolbar-title
        class="primary--text text--darken-3 pr-4"
        style="font-family: Lato">
        <v-img
          class="pl-2"
          :max-width="300"
          src="./assets/makewithtech900.png" />
      </v-toolbar-title>
      <v-spacer />

      <v-btn
        icon
        to="/"
        class="primary--text mx-2"
        data-cy="home">
        <v-icon
          large
          light
          color="#030b79">
          mdi-home
        </v-icon>
      </v-btn>

      <v-divider
        vertical
        class="ml-4" />

      <v-btn
        icon
        to="/notifications"
        class="primary--text mx-2"
        data-cy="notifications"
        :disabled="notLoggedIn">
        <v-icon
          large
          light
          color="#030b79">
          mdi-bell-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        :disabled="notLoggedIn"
        to="/mailsync"
        class="primary--text mx-2"
        data-cy="mailsync">
        <v-icon
          large
          light
          color="#030b79">
          mdi-email-sync-outline
        </v-icon>
      </v-btn>
      <v-btn
        icon
        to="/validate"
        class="primary--text mx-2"
        data-cy="validate"
        :disabled="notLoggedIn">
        <v-icon
          large
          light
          color="#030b79">
          mdi-account-check
        </v-icon>
      </v-btn>
      <v-btn
        icon
        to="/metrics"
        class="primary--text mx-2"
        data-cy="metrics"
        :disabled="notLoggedIn">
        <v-icon
          large
          light
          color="#030b79">
          mdi-table
        </v-icon>
      </v-btn>
      <v-btn
        icon
        to="/maintenance"
        class="primary--text mx-2"
        data-cy="maintenance"
        :disabled="notLoggedIn">
        <v-icon
          large
          light
          color="#030b79">
          mdi-wrench-clock
        </v-icon>
      </v-btn>

      <v-divider vertical />

      <v-btn
        :disabled="notLoggedIn"
        icon
        to="/logout"
        class="primary--text mx-2"
        data-cy="logout">
        <v-icon
          large
          light
          color="#030b79">
          mdi-logout
        </v-icon>
      </v-btn>
      <v-btn
        icon
        to="/login"
        class="primary--text mx-2"
        data-cy="login"
        :disabled="!notLoggedIn">
        <v-icon
          large
          light
          color="#030b79">
          mdi-login
        </v-icon>
      </v-btn>
    </v-app-bar>

    <!-- Main Content Starts Here -->

    <v-main>
      <v-container
        fluid
        class="pb-5 mb-5">
        <router-view class="mt-0 pt-0"></router-view>
      </v-container>
    </v-main>

    <!-- Modal used to display login errors -->

    <v-dialog
      v-model="$store.state.loginMessageTrigger"
      max-width="500">
      <v-card>
        <v-card-title> Login Message </v-card-title>
        <v-card-text class="text-center">
          <span
            class="text-h6 text-center"
            v-html="$store.state.loginMessage"></span>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="green darken-1"
            text
            @click="
              $store.state.loginMessageTrigger = false;
              $store.state.loginMessage = '';
            ">
            Ok
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
  export default {
    name: "App",

    components: {},

    data: () => ({}),

    created() {
      //
      // If the user reloads the page from the browser go back to the home page
      // to ensure all of the application states are properly handled. However
      // if we are already on the home page do not reroute.
      //
      if (performance.getEntriesByType("navigation")[0].type == "reload") {
        if (window.location.pathname !== "/") {
          this.$router.push({ path: "/" });
        }
      }
    },

    mounted() {
      this.$store.state.loginMessageTrigger = false;
      this.checkLoginStatus();
    },
    computed: {
      notLoggedIn: function () {
        if (this.$store.state.cognitoUser === null) {
          return true;
        } else {
          return false;
        }
      },
    },
  };
</script>
