<template>
  <v-container fluid>
    <v-sheet
      height="600"
      class="background-image">
      <v-row justify="space-around">
        <v-col>
          <p class="mt-5 text-h3 text-center">Enable/Disable Maintenance Notice</p>

          <p class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SITE FOR THE EXCLUSTIVE OF USE MAKEWITHTECH AUTHORIZED PERSONEL
          </p>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-spacer />
        <v-col cols="5">
          <h3>Current Maintenance Mode Values</h3>
          <p class="mt-5 text-center">Active: {{ maintenanceMode }}</p>
          <p class="mt-5 text-center">Message: {{ maintenanceMessage }}</p>
        </v-col>
        <v-spacer />
      </v-row>
      <v-row justify="space-around">
        <v-spacer />
        <v-col cols="5">
          <h3>New Maintenance Mode Values</h3>
          <v-switch
            v-model="newMaintenanceMode"
            label="Maintenance Message Active"
            outlined>
          </v-switch>
          <v-textarea
            v-model="newMaintenanceMessage"
            label="Enter maintenance message in HTML format"
            outlined>
          </v-textarea>
          <v-btn
            :loading="loading"
            :disabled="loading"
            @click="updateMaintenanceMode()"
            block
            x-large
            class="text-subtitle-1 secondary white--text">
            Update -- Active Immediately
          </v-btn>
        </v-col>
        <v-spacer />
      </v-row>
    </v-sheet>
  </v-container>
</template>

<style>
  .background-image {
    background: url("../../public/bg.jpg") no-repeat top 10px center / cover;
    background-size: cover;
  }
</style>

<script>
  import { API } from "aws-amplify";
  import * as queries from "../graphql/queries";
  import * as mutations from "../graphql/mutations";

  export default {
    name: "LoginPage",
    components: {},
    data() {
      return {
        maintenanceMessage: "",
        maintenanceMode: false,
        newMaintenanceMode: false,
        newMaintenanceMessage: "",
        loading: false,
      };
    },
    async mounted() {
      await this.getMaintenanceMode();
    },
    //
    // Maintenace is misspelled in the graphql schema
    //
    // query MyQuery {
    //   getMWTControls(id: "1") {
    //     id
    //     maintananceMode
    //     maintenanceMessage
    //   }
    // }
    //
    methods: {
      getMaintenanceMode: async function () {
        const maintenanceRows = await API.graphql({
          query: queries.getMWTControls,
          variables: { id: "1" },
        });
        this.maintenanceMode = maintenanceRows.data.getMWTControls.maintananceMode;
        this.maintenanceMessage = maintenanceRows.data.getMWTControls.maintenanceMessage;
      },
      updateMaintenanceMode: async function () {
        if (this.newMaintenanceMessage === "") {
          this.newMaintenanceMessage = this.maintenanceMessage;
        }
        let maintenanceRecord = {
          id: "1",
          maintananceMode: this.newMaintenanceMode,
          maintenanceMessage: this.newMaintenanceMessage,
        };
        await API.graphql({
          query: mutations.updateMWTControls,
          variables: { input: maintenanceRecord },
        });
        await this.getMaintenanceMode();
      },
    },
  };
</script>
