<template>
  <!-- IMS <amplify-authenticator> -->
  <!-- <v-app id="inspire">
    <v-app-bar
      app
      color="white"
      flat>
      <v-avatar
        :color="$vuetify.breakpoint.smAndDown ? 'grey darken-1' : 'transparent'"
        size="32"></v-avatar>

      <v-tabs
        centered
        class="ml-n9"
        color="grey darken-1">
        <v-tab
          v-for="link in links"
          :key="link">
          {{ link }}
        </v-tab>
      </v-tabs>
    </v-app-bar> -->

  <div>
    <v-main v-if="noAuthorization">
      <h1 class="mt-5 text-center">Please login with a valid user account.</h1>
    </v-main>
    <v-main
      v-else
      class="grey lighten-3">
      <v-form
        ref="form"
        v-model="valid">
        <v-container>
          <v-row>
            <v-col
              class="pr-5"
              cols="9">
              <v-text-field
                :disabled="!connected"
                v-model="newTitle"
                label="Title"
                :rules="titleRules"
                required></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              class="pr-5"
              cols="9">
              <v-textarea
                :disabled="!connected"
                :rules="contentRules"
                v-model="newMessage"
                outlined
                name="input-7-2"
                label="Your Message"
                required></v-textarea>
            </v-col>
            <v-col cols="2">
              <v-select
                :disabled="!connected"
                :rules="recipientRules"
                label="Recipient"
                :items="recipients"
                @change="(selection) => recipientSelected(selection)"
                outlined
                required></v-select>
              <v-select
                :disabled="!connected"
                :rules="classRules"
                label="Class"
                :items="classes"
                @change="(selection) => classSelected(selection)"
                outlined
                required></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11">
              <v-btn
                depressed
                color="primary mx-2"
                :disabled="!connected"
                @click="onSendClick">
                Send
              </v-btn>
              <v-btn
                depressed
                color="primary mx-2"
                :disabled="connected"
                @click="onConnectClick">
                Connect
              </v-btn>
              <v-btn
                depressed
                color="primary mx-2"
                :disabled="!connected"
                @click="onDisconnectClick">
                Disconnect
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-form>

      <v-container>
        <v-data-table
          :headers="headers"
          :items="messages"
          :items-per-page="5"
          class="elevation-1"></v-data-table>
      </v-container>

      <v-snackbar
        v-model="snackbar"
        :timeout="snackbarTimeout">
        {{ snackbarText }}

        <template v-slot:action="{ attrs }">
          <v-btn
            color="blue"
            text
            v-bind="attrs"
            @click="snackbar = false">
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <!-- </v-app> -->
    <!-- IMS </amplify-authenticator> -->
  </div>
</template>

<script>
  import { Auth } from "aws-amplify";
  import axios from "axios";
  import awsmobile from "@/aws-exports";
  //import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

  export default {
    name: "NotificationAdmin",

    data: () => ({
      valid: true,
      loading: true,
      newTitle: "",
      newMessage: "",
      newRecipient: "",
      newClass: "",
      connected: false,
      webSocket: null,
      // links: ["Messages"],
      titleRules: [(v) => !!v || "Please add a title."],
      contentRules: [(v) => !!v || "Message is required."],
      recipientRules: [(v) => !!v || "Recipient missing."],
      classRules: [(v) => !!v || "Class must be set."],
      headers: [
        { text: "Message", value: "messageId" },
        { text: "Time", value: "sendTimeIso" },
        { text: "Headline", value: "headline" },
        { text: "Content", value: "content" },
      ],
      messages: [],
      recipients: [],
      classes: ["temporary", "informal", "marketing", "heartbeat"],
      snackbar: false,
      snackbarText: "",
      snackbarTimeout: 5000,
      // IMS unsubscribeAuth: null,
      authData: null,
      noAuthorization: false,
    }),
    computed: {
      backend: function () {
        const branch = process.env.VUE_APP_BRANCH;
        console.log("Current branch: ", branch);
        if (branch === "dev") {
          console.log("Setting keys to dev");
          return {
            apiKey: process.env.VUE_APP_API_KEY_DEV,
            apiEndpoint: process.env.VUE_APP_BACKEND_API_DEV,
            wssEndpoint: process.env.VUE_APP_BACKEND_WSS_DEV,
          };
        } else if (branch === "test") {
          console.log("Setting keys to test");
          return {
            apiKey: process.env.VUE_APP_API_KEY_TEST,
            apiEndpoint: process.env.VUE_APP_BACKEND_API_TEST,
            wssEndpoint: process.env.VUE_APP_BACKEND_WSS_TEST,
          };
        } else {
          console.log("Setting keys to production");
          return {
            apiKey: process.env.VUE_APP_API_KEY_PROD,
            apiEndpoint: process.env.VUE_APP_BACKEND_API_PROD,
            wssEndpoint: process.env.VUE_APP_BACKEND_WSS_PROD,
          };
        }
      },
    },
    async mounted() {
      if (this.checkAuthorization()) {
        if (this.loading) {
          this.loading = false;

          console.log(`Fetching initial data for signed-in user ...`);

          // IMS Get cognito data from Vuex
          // This component is only invoked if the user is already logged in so
          // no need to check here.

          this.authData = this.$store.state.cognitoUser;

          const headers = {
            "X-API-KEY": this.backend.apiKey,
            "Content-Type": "application/json",
          };

          // getting available pool users
          const fetchUsersCommand = {
            messageType: "FetchUsersEvent",
            userPoolId: awsmobile.aws_user_pools_id,
            attributes: ["sub"],
          };
          console.log(`About to send admin command: ${JSON.stringify(fetchUsersCommand)}`);
          const users = await axios
            .post(this.backend.apiEndpoint + "/v1/admin", fetchUsersCommand, {
              headers: headers,
            })
            .then(function (response) {
              console.log(`Server responded with: ${JSON.stringify(response.data)}`);
              const result = response.data.result;
              if (result) {
                return result.users.map((user) => {
                  return { text: `User: ${user.username}`, value: `user#${user.sub}` };
                });
              }
            })
            .catch(function (error) {
              console.error(`Unable send new command to admin backend:` + error);
              return [];
            });

          this.recipients.push({ text: "Group: All", value: "group#all" });
          this.recipients.push({ text: "Group: Subscribers", value: "group#subscribers" });
          this.recipients.push(...users);

          const fetchMessagesCommand = {
            messageType: "FetchUserMessagesEvent",
            userId: this.authData.attributes?.sub,
            sinceTimestamp: new Date("2022-01-01T00:00:00Z").getTime(),
          };

          console.log(`About to send admin command: ${JSON.stringify(fetchMessagesCommand)}`);
          const userMessages = await axios
            .post(this.backend.apiEndpoint + "/v1/admin", fetchMessagesCommand, {
              headers: headers,
            })
            .then(function (response) {
              console.log(`Server responded with: ${JSON.stringify(response.data)}`);
              return response.data.messages || [];
            })
            .catch(function (error) {
              console.error(`Unable send new command to admin backend:` + error);
              return [];
            });

          console.log(`Found ${userMessages.length} message(s) ...`);
          this.messages = userMessages;
        }
      } else {
        this.noAuthorization = true;
      }
    },
    methods: {
      recipientSelected(selection) {
        this.newRecipient = selection;
      },
      classSelected(selection) {
        this.newClass = selection;
      },
      async onSendClick(e) {
        e.preventDefault();

        console.log(`Send clicked: ${this.newMessage}`);
        if (this.$refs.form.validate()) {
          const [recipientType, recipientValue] = this.newRecipient.split("#");

          const userSession = await Auth.currentSession();
          const accessToken = userSession.getAccessToken();
          const payload = accessToken.decodePayload();
          const sendMessageCommand = {
            messageType: "SendMessageEvent",
            recipient: {
              recipientType: recipientType,
              value: recipientValue,
            },
            sender: payload.username,
            class: this.newClass,
            headline: this.newTitle,
            content: this.newMessage,
          };
          const headers = {
            "X-API-KEY": this.backend.apiKey,
            "Content-Type": "application/json",
          };
          console.log(`About to send admin command: ${JSON.stringify(sendMessageCommand)}`);
          await axios
            .post(this.backend.apiEndpoint + "/v1/admin", sendMessageCommand, { headers: headers })
            .then(function (response) {
              console.log(`Server responded with: ${JSON.stringify(response.data)}`);
            })
            .catch(function (error) {
              console.error(`Unable send new message to backend:` + error);
            });
          this.$refs.form.reset();
        }
      },
      onDisconnectClick(e) {
        e.preventDefault();
        console.log(`Disconnect clicked..`);
        this.webSocket.close();
      },
      async onConnectClick(e) {
        e.preventDefault();
        console.log(`Connect clicked..`);

        const userSession = await Auth.currentSession();
        const accessToken = userSession.getAccessToken();
        const jwtToken = accessToken.getJwtToken();
        const wssUrl = `${this.backend.wssEndpoint}?state=${jwtToken}`;

        console.log("Creating WebSocket ..");
        this.webSocket = new WebSocket(wssUrl);

        const heartbeat = () => {
          if (!this.webSocket || this.webSocket.readyState !== 1) {
            console.warn(`Unable to send heartbeat ..`);
            return;
          }

          console.log("Sending heartbeat ..");
          this.webSocket.send(
            JSON.stringify({
              action: "heartbeat",
            })
          );

          setTimeout(heartbeat, 30 * 1000);
        };

        this.webSocket.onopen = () => {
          console.log("WebSocket opened ...");
          heartbeat();
          this.connected = true;
        };
        this.webSocket.onclose = () => {
          console.log("WebSocket closed ...");
          this.connected = false;
        };
        this.webSocket.onerror = () => {
          console.log("WebSocket error");
        };
        this.webSocket.onmessage = (e) => {
          console.log(`Received message: ${e.data}`);
          const data = JSON.parse(e.data);
          if (data.class !== "heartbeat") {
            this.snackbar = false;
            this.snackbarText = `${data.headline || ""}<br /><em>${data.content || ""}</em>`;
            this.snackbar = true;
            this.messages.push({
              messageId: data.messageId || "",
              sendTimeIso: data.sendTimeIso || "",
              headline: data.headline || "",
              content: data.content || "",
            });
          }
        };
      },
    },
  };
</script>
