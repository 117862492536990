<template>
  <v-container fluid>
    <v-sheet
      height="600"
      class="background-image">
      <v-row justify="space-around">
        <v-col>
          <p class="mt-5 text-h3 text-center">Mailing list synchronization</p>

          <p class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SITE FOR THE EXCLUSIVELY FOR THE PRIVATE USE OF MAKEWITHTECH
            AUTHORIZED PERSONNEL
          </p>
          <p>{{ discourseApiKey }}</p>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col
          cols="12"
          md="4">
          <v-btn
            v-if="
              checkResult &&
              checkResult?.cognito === true &&
              checkResult?.discourse === true &&
              checkResult?.zoho === true
            "
            @click="synchronizeUsersDryRun()"
            block
            x-large
            class="text-subtitle-1 secondary white--text">
            Dry run
          </v-btn>
        </v-col>
        <v-col
          cols="12"
          md="4">
          <v-btn
            v-if="
              checkResult &&
              checkResult?.cognito === true &&
              checkResult?.discourse === true &&
              checkResult?.zoho === true
            "
            @click="synchronizeUsers()"
            block
            x-large
            class="text-subtitle-1 secondary white--text">
            Synchronize
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col
          cols="12"
          md="7">
          <div
            v-if="
              !(
                checkResult &&
                checkResult?.cognito === true &&
                checkResult?.discourse === true &&
                checkResult?.zoho === true
              )
            ">
            <h3 class="red--text">{{ globalErrorMessage }}</h3>
          </div>
          <div class="mt-4">
            <v-alert
              outlined
              type="info"
              color="light-blue darken-1"
              v-if="synchronizationStatus === 'started'">
              Synchronization in progress.
              <template v-slot:prepend>
                <v-progress-circular
                  indeterminate
                  size="24"
                  color="primary"
                  class="mr-3"></v-progress-circular>
              </template>
            </v-alert>
            <v-alert
              outlined
              type="info"
              color="light-blue darken-1"
              v-if="synchronizationStatus === 'dryrun'">
              Synchronization in progress (dry run).
              <template v-slot:prepend>
                <v-progress-circular
                  indeterminate
                  size="24"
                  color="primary"
                  class="mr-3"></v-progress-circular>
              </template>
            </v-alert>
            <v-alert
              outlined
              type="success"
              color="green darken-1"
              v-if="synchronizationStatus === 'finished'">
              Synchronization completed.
              <div class="mt-3">Added a total of {{ syncStats.addedUsersCognito + syncStats.addedUsersDiscourse }} {{ syncStats.addedUsersCognito + syncStats.addedUsersDiscourse === 1 ? 'user' : 'users' }}, including {{ syncStats.addedUsersCognito }} added to Cognito List and {{ syncStats.addedUsersDiscourse }} added to Discourse List.</div>
            </v-alert>
            <v-alert
              outlined
              type="success"
              color="green darken-1"
              v-if="synchronizationStatus === 'dryrun-finished'">
              Dry run synchronization completed.
              <div class="mt-3">There are {{ syncStats.addedUsersCognito + syncStats.addedUsersDiscourse }} {{ syncStats.addedUsersCognito + syncStats.addedUsersDiscourse === 1 ? 'user' : 'users' }} to add, including {{ syncStats.addedUsersCognito }} to Cognito List and {{ syncStats.addedUsersDiscourse }} to Discourse List.</div>
              <div class="mt-3">
                Cognito users: {{ syncStats.cognito.users }} ({{ syncStats.cognito.time }}s)
              </div>
              <div>
                Discourse users: {{ syncStats.discourse.users }} ({{ syncStats.discourse.time }}s)
              </div>
              <div>
                Zoho Campaigns users (Cognito List): {{ syncStats.zoho.users }} ({{ syncStats.zoho.time }}s)
              </div>
              <div>
                Zoho Campaigns users (Discourse List): {{ syncStats.zoho2.users }} ({{ syncStats.zoho2.time }}s)
              </div>
              <div class="mt-3">Latest synchronization time: {{ syncStats.latestSyncTime }}</div>
            </v-alert>
            <v-alert
              outlined
              type="error"
              color="red darken-1"
              v-if="synchronizationStatus === 'error'">
              Synchronization error.
              <div>{{ synchronizationMessage }}</div>
            </v-alert>
          </div>
        </v-col>
      </v-row>
      <div v-if="checkResult">
        <v-row justify="space-around">
          <v-col
            col="12"
            md="8">
            <h2 class="text-center mt-4 pt-4">Status:</h2>
          </v-col>
        </v-row>
        <v-row
          justify="center"
          align-content="center">
          <v-col cols="3">
            <p>AWS Cognito</p>
            <div v-if="checkResult?.cognito === true">
              <p class="green--text">User pool linked</p>
            </div>
            <div v-else-if="checkResult?.cognito === false">
              <p class="red--text">User pool not linked</p>
            </div>
            <div v-else>...</div>
          </v-col>
          <v-col cols="3">
            <p>Discourse</p>

            <div v-if="checkResult?.discourse === true">
              <p class="green--text">API key entered</p>
            </div>
            <div v-else-if="checkResult?.discourse === false">
              <p class="red--text">Not authenticated</p>
            </div>
            <div v-else>...</div>
          </v-col>
          <v-col cols="3">
            <p>Zoho Campaigns</p>
            <div v-if="checkResult?.zoho === true">
              <p class="green--text">Authenticated</p>
            </div>
            <div v-else-if="checkResult?.zoho === false">
              <p class="red--text">Not authenticated</p>
            </div>
            <div v-else>...</div>
          </v-col>
        </v-row>
      </div>
      <v-row
        justify="space-around"
        v-if="checkResult">
        <v-col
          cols="12"
          md="7">
          <v-btn
            @click="checkStatus()"
            block
            x-large
            class="text-subtitle-1 secondary white--text">
            <v-icon
              small
              class="me-2"
              >mdi-refresh</v-icon
            >
            Re-Check status
          </v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col
          col="12"
          md="8">
          <v-alert
            outlined
            v-if="checkResult?.cognito === false">
            <div class="text-h6">How to integrate AWS Cognito user pool?</div>

            <ul class="mt-2">
              <li>
                In the Lambda function associated with the app, ensure that the execution role is
                authorized for read access to Cognito by attaching the "AmazonCognitoReadOnly"
                policy to the role.
              </li>
              <li>
                Set up the "CognitoUserPoolId" environment variable in the Lambda function with the
                User Pool ID you want to integrate with.
              </li>
              <li>
                If the integration doesn't work, check the output of the Lambda function logs for
                additional information on any errors that occurred.
              </li>
            </ul>
          </v-alert>
          <v-alert
            outlined
            v-if="checkResult?.discourse === false">
            <div class="text-h6">How to integrate Discourse forum?</div>
            <p class="mt-1">
              In your Discourse forum, navigate to the "Admin" section and select "API". Generate a
              new API key by clicking the "New API Key" button and following the prompts.
            </p>
            <p>Set up the following environment variables in the Lambda function:</p>
            <ul class="mt-2">
              <li>DISCOURSE_API_KEY: Your Discourse API key</li>
              <li>DISCOURSE_API_USERNAME: Discourse API username</li>
              <li>
                DISCOURSE_API_BASE_URL: This is typically the domain name where your Discourse forum
                is hosted
              </li>
            </ul>
            <p class="mt-1">
              If the integration doesn't work, check the output of the Lambda function logs for
              additional information on any errors that occurred.
            </p>
          </v-alert>
          <v-alert
            outlined
            v-if="checkResult?.zoho === false">
            <div class="text-h6">How to integrate Zoho Campaigns?</div>
            <p class="mt-1">Get the access codes</p>
            <ul class="mt=2">
              <li>Once you have logged in to your Zoho account, go to the API section.</li>
              <li>Then, if you have not started creating applications, click on "Get Started".</li>
              <li>After that, click on the "Create Now" option, under the "Self Client" card.</li>
              <li>
                This will create the credentials that you need in order to connect Zoho Account. In
                the next page, click on "Create".
              </li>
              <li>
                A pop-up will appear, asking you to confirm the self-client activation. Click on
                "OK".
              </li>
              <li>
                This will open a section to prepare codes. Complete the input fields and click
                Continue. <br />
                Scope: In this first field, make sure you insert this: ZohoCampaigns.contact.ALL<br />
                Time Duration: Here, you can set the period of time after which the "Access Code"
                will disappear. If you want to change the default one, which is 3 minutes, click on
                the field and choose the suitable time period.<br />
                Scope Description: Insert a description of the scope in this field.
              </li>
              <li>
                Once you're done with these, click on "Create". The "Access Code" will be generated,
                ready to be copied.
              </li>
            </ul>
            <p class="mt-2">Set up the following environment variables in the Lambda function:</p>
            <ul class="mt-2">
              <li>
                ZOHO_CAMPAIGNS_API_DOMAIN: this should be domain of the URL to your account. Check
                url in your browser, it should be "zoho.com" or "zoho.eu"
              </li>
              <li>
                ZOHO_CAMPAIGNS_CLIENT_ID: this can be found in the code generation popup, but on the
                second tab called "Client Secret"
              </li>
              <li>
                ZOHO_CAMPAIGNS_CLIENT_SECRET: this can be found right below the "Client ID" code
              </li>
              <li>ZOHO_CAMPAIGNS_REFRESH_TOKEN: use the tool below to generate refresh_token</li>
              <li>
                ZOHO_CAMPAIGNS_LIST_KEY: the listkey represents the mailing list you're going to
                synchronize. To obtain the list key, navigate to Contacts > Manage Lists, select the
                mailing list, click Setup, scroll down to view the List Key, and copy it.
              </li>
            </ul>
            <p class="mt-1">
              If the integration doesn't work, check the output of the Lambda function logs for
              additional information on any errors that occurred.
            </p>
          </v-alert>
          <v-alert
            outlined
            v-if="checkResult?.zoho === false">
            <div class="text-h6">Obtain Zoho Campaigns API refresh token.</div>
            <ul class="mt-2">
              <li>
                Set up the following environment variables in the Lambda function:
                ZOHO_CAMPAIGNS_API_DOMAIN, ZOHO_CAMPAIGNS_CLIENT_ID, ZOHO_CAMPAIGNS_CLIENT_SECRET
              </li>
              <li>Paste access code to obtain refresh token value:</li>
            </ul>
            <v-text-field
              v-model="zohoAccessCode"
              label="Access Code"></v-text-field>
            <v-btn
              type="submit"
              block
              class="mt-2"
              @click="getZohoRefreshToken()"
              >Submit</v-btn
            >

            <p
              class="mt-5"
              v-if="zohoRefreshToken">
              Your refresh token: {{ zohoRefreshToken }}
            </p>
            <p v-if="zohoRefreshToken">
              Please set this value for the ZOHO_CAMPAIGNS_REFRESH_TOKEN environment variable for
              the Lambda function.
            </p>
            <p
              class="mt-5 red--text"
              v-if="zohoRefreshTokenErrorMessage">
              {{ zohoRefreshTokenErrorMessage }}
            </p>
          </v-alert>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<style>
  .background-image {
    background: url("../../public/bg.jpg") no-repeat top 10px center / cover;
    background-size: cover;
  }
</style>

<script>
  import config from "../config.json";
  // IMS import { Auth, API } from "aws-amplify";
  //import * as mutations from "../graphql/mutations";

  export default {
    name: "LoginPage",
    components: {},
    data() {
      return {
        discourseApiKey: config.discourseApiKey,
        checkResult: {},
        globalErrorMessage: null,
        zohoAccessCode: "",
        zohoRefreshToken: "",
        zohoRefreshTokenErrorMessage: "",
        synchronizationStatus: null,
        synchronizationMessage: null,
        syncStats: {},
      };
    },
    async created() {
      try {
        await this.$store.dispatch("userListSync/checkAPIKeys");
        this.checkResult = this.$store.state.userListSync.checkResult;
        if (!this.checkResult?.cognito || !this.checkResult.discourse || !this.checkResult.zoho)
          this.globalErrorMessage =
            "To start synchronization, please configure access to Cognito User pool, and Discourse / Zoho API keys.";
      } catch (error) {
        this.checkResult = null;
        this.globalErrorMessage = error;
      }
    },
    methods: {
      checkStatus: async function () {
        try {
          this.checkResult = {};
          await this.$store.dispatch("userListSync/checkAPIKeys");
          this.checkResult = this.$store.state.userListSync.checkResult;
        } catch (error) {
          this.checkResult = null;
          this.globalErrorMessage = error;
        }
      },
      getZohoRefreshToken: async function () {
        this.zohoRefreshTokenErrorMessage = null;
        try {
          await this.$store.dispatch("userListSync/getZohoRefreshToken", {
            accessCode: this.zohoAccessCode,
          });
          this.zohoRefreshToken = this.$store.state.userListSync.zohoRefreshToken;
        } catch (error) {
          this.zohoRefreshTokenErrorMessage = error;
        }
      },
      synchronizeUsers: async function () {
        this.synchronizationStatus = "started";
        try {
          await this.$store.dispatch("userListSync/startSynchronization");
          this.synchronizationStatus = "finished";
          if (this.$store.state.userListSync.syncStatus) {
            this.syncStats = {
              addedUsersCognito: this.$store.state.userListSync.syncStatus.addedUsersCognito,
              addedUsersDiscourse: this.$store.state.userListSync.syncStatus.addedUsersDiscourse,
              latestSyncTime: new Date(this.$store.state.userListSync.syncStatus.latestSyncTime)
                .toISOString()
                .substring(0, 19)
                .replace("T", " "),
              ...this.$store.state.userListSync.syncStatus.executionStats,
            };
          }
        } catch (error) {
          this.synchronizationStatus = "error";
          this.synchronizationMessage = error;
        }
      },
      synchronizeUsersDryRun: async function () {
        this.synchronizationStatus = "dryrun";
        try {
          await this.$store.dispatch("userListSync/startSynchronization", { dryRun: true });
          if (this.$store.state.userListSync.syncStatus) {
            this.syncStats = {
              addedUsersCognito: this.$store.state.userListSync.syncStatus.addedUsersCognito,
              addedUsersDiscourse: this.$store.state.userListSync.syncStatus.addedUsersDiscourse,
              latestSyncTime: new Date(this.$store.state.userListSync.syncStatus.latestSyncTime)
                .toISOString()
                .substring(0, 19)
                .replace("T", " "),
              ...this.$store.state.userListSync.syncStatus.executionStats,
            };
          }
          this.synchronizationStatus = "dryrun-finished";
        } catch (error) {
          console.log("error sycstatus", this.$store.state.userListSync.syncStatus);
          this.synchronizationStatus = "error";
          this.synchronizationMessage = error;
        }
      },
    },
  };
</script>
