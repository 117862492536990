import Vue from "vue";
import Vuex from "vuex";
import userListSync from "./userListSync.js";

Vue.use(Vuex);

//
// A component can access the state varables directly via this.$store.state.variable-name or
// via the mutations (setters) or getters below.
//
// When using mutations and/or getters additional work can be done in addition to accessing the
// value.  The sysntax is:
//
// this.$store.getters.variable-name
// this.$store.commit("mutation-name", value);
//

export const store = new Vuex.Store({
  state: {
    previousPage: "",
    loginMessage: "",
    loginMessageTrigger: false,
    cognitoUser: null,
    administrator: false,
    editor: false,
  },
  mutations: {
    previous(state, previousPage) {
      state.previousPage = previousPage;
    },
    setCognitoUser(state, cognitoUser) {
      state.cognitoUser = cognitoUser;
    },
  },
  getters: {
    previousPage: (state) => state.previousPage,
  },
  modules: {
    userListSync,
  },
});
