<template>
  <div class="account-page pb-16">
    <v-container fluid>
      <!-- Modal used to display login errors -->

      <v-dialog
        v-model="$store.state.loginMessage"
        max-width="500">
        <v-card>
          <v-card-title> Login to Models Administration </v-card-title>
          <v-card-text>
            <span class="text-h6">{{ $store.state.loginMessage }}</span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="$store.state.loginMessage = ''">
              Ok
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <!-- Main Content Area Starts Here -->

      <v-row justify="space-around">
        <v-col
          col="12"
          md="6">
          <p class="mt-5 text-h3 text-center">Administration Utilities</p>

          <div class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SIGN FOR THE EXCLUSIVE OF USE MAKEWITHTECH AUTHORIZED PERSONNEL
          </div>
        </v-col>
      </v-row>
      <v-row
        justify="space-around"
        v-if="this.$store.state.cognitoUser !== null">
        <v-col
          col="12"
          md="6">
          <center>
            <v-btn
              data-cy="signOutButton"
              type="button"
              large
              @click="sign_out()"
              class="secondary white--text font-weight-bold ma-3 mx-auto">
              SIGN OUT
            </v-btn>
          </center>
        </v-col>
      </v-row>
      <v-row
        justify="space-around"
        v-else>
        <v-col
          col="12"
          md="6">
          <h1 class="mt-5 text-center">Not Currently Logged In</h1>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<style></style>

<script>
  import { Auth } from "aws-amplify";
  export default {
    name: "LoginPage",
    components: {},
    data() {
      return {};
    },
    mounted() {
      this.$store.state.loginMessageTrigger = false;
    },
    methods: {
      sign_out: async function () {
        try {
          const response = await Auth.signOut({
            global: true,
          });
          console.log("Logout response: ", response);
          this.$store.state.loginMessage = "";
          this.$store.state.loginMessageTrigger = false;
          this.$store.state.cognitoUser = null;
          // this.$router.push({
          //   path: "/",
          // });
        } catch (error) {
          this.$store.state.loginMessage = "Error Signing Out of the Admin Application";
          this.$store.state.cognitoUser = null;
          this.$store.state.loginMessageTrigger = true;
        }
      },
    },
  };
</script>
