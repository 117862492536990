<template>
  <v-container fluid>
    <v-sheet
      height="600"
      class="background-image">
      <v-row justify="space-around">
        <v-col>
          <p class="mt-5 text-h3 text-center">Application Metrics</p>

          <p class="font-italic mt-4 pt-4 text-center">
            THIS IS A PRIVATE SITE FOR THE EXCLUSIVE OF USE MAKEWITHTECH AUTHORIZED PERSONNEL
          </p>
        </v-col>
      </v-row>
      <v-row justify="space-around">
        <v-col>
          <p class="mt-5 text-h5 text-center">
            Registered (Cognito) User Count: {{ cognitoUsers }}
          </p>
          <p class="mt-5 text-h5 text-center">Total user profiles: {{ subscriberTotal }}</p>
          <p class="mt-5 text-h5 text-center">Percent of verified users: {{ subscriberRatio }}</p>
          <br />
          <p class="mt-5 text-h5 text-center">Users with subscriptions: {{ subscriberCount }}</p>
          <p class="mt-5 text-h5 text-center">Percent of subscribers: {{ subscriberPercent }}</p>
          <br />
          <p class="mt-5 text-h5 text-center">Total Model Count: {{ modelCount }}</p>
          <p class="mt-5 text-h5 text-center">Public Model Count: {{ modelPublicCount }}</p>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<style>
  .background-image {
    background: url("../../public/bg.jpg") no-repeat top 10px center / cover;
    background-size: cover;
  }
</style>

<script>
  import {
    CognitoIdentityProviderClient,
    DescribeUserPoolCommand,
  } from "@aws-sdk/client-cognito-identity-provider";
  import config from "../config.json";
  import { API, Auth } from "aws-amplify";
  import * as queries from "../graphql/queries";

  export default {
    name: "LoginPage",
    components: {},
    data() {
      return {
        cognitoUsers: 0,
        modelCount: 0,
        modelPublicCount: 0,
        subscriberCount: 0,
        subscriberTotal: 0,
      };
    },
    computed: {
      subscriberRatio: function () {
        return parseFloat((this.subscriberTotal / this.cognitoUsers) * 100).toFixed(2);
      },
      subscriberPercent: function () {
        return parseFloat((this.subscriberCount / this.subscriberTotal) * 100).toFixed(2);
      },
    },
    mounted() {
      this.countUsers();
      this.countModels();
    },
    methods: {
      countUsers: async function () {
        const credentials = await Auth.currentCredentials();
        const cognitoClient = new CognitoIdentityProviderClient({
          region: config.region,
          credentials: credentials,
        });

        const params = {
          UserPoolId: config.userPoolId,
        };

        const cognitoCommand = new DescribeUserPoolCommand(params);

        try {
          const data = await cognitoClient.send(cognitoCommand);
          this.cognitoUsers = data.UserPool.EstimatedNumberOfUsers;
        } catch (error) {
          console.log(error);
        }

        let profileRows;
        let graphQLQueryFilters = { id: { exists: true } };
        let graphQLQueryVariables = {
          filter: graphQLQueryFilters,
        };

        profileRows = await API.graphql({
          query: queries.searchMWTUserProfiles,
          variables: graphQLQueryVariables,
        });

        this.subscriberTotal = profileRows.data.searchMWTUserProfiles.total || 0;

        profileRows;
        graphQLQueryFilters = { subscriptionId: { exists: true } };
        graphQLQueryVariables = {
          filter: graphQLQueryFilters,
        };

        profileRows = await API.graphql({
          query: queries.searchMWTUserProfiles,
          variables: graphQLQueryVariables,
        });

        this.subscriberCount = profileRows.data.searchMWTUserProfiles.total || 0;
      },
      countModels: async function () {
        let modelRows = await API.graphql({
          query: queries.searchMWTModels,
        });

        this.modelCount = modelRows.data.searchMWTModels.total || 0;

        let graphQLQueryFilters = {
          and: [{ hidden: { eq: false } }, { private: { eq: false } }],
        };
        let graphQLQueryVariables = {
          limit: 10000,
          filter: graphQLQueryFilters,
        };

        modelRows = await API.graphql({
          query: queries.searchMWTModels,
          variables: graphQLQueryVariables,
        });

        this.modelPublicCount = modelRows.data.searchMWTModels.total || 0;
      },
    },
  };
</script>
